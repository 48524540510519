<template>
  <div class="chart-container">
    <div class="loading" v-if="loading"><i class="el-icon-loading"></i></div>
    <div ref="chart" style="height: 300px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { get } from 'lodash';
import { workingHoursFormatter } from '../utils';
import colors from '@constants/colors';
import * as api from '@constants/apis';
import moment from 'moment'

let barChart = null;

export default {
  name: 'App',
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.init().then(() => {
      window.addEventListener('resize', barChart.resize);
    });
    moment.locale("zh-cn");
  },
  beforeUnmount() {
    if (barChart && barChart.resize) {
      window.removeEventListener('resize', barChart.resize);
    }
  },
  methods: {

    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_EFFECTIVE_WORK_HOURS);
        const dataForToday = get(res, ['data', 'todayValidWorkStats'], []);
        const dataForLastWeek = get(res, ['data', 'lastWeekValidWorkStats'], []);
        var temp = 0;
        var count = 0;
        dataForToday.forEach(element => {
          temp += element.rate;
          if(element.rate >0 ){
            count ++;
          }
        });
        const percent = count == 0 ? 0 : temp/count;
        this.renderChart(dataForToday, dataForLastWeek, percent);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    renderChart(dataForToday, dataForLastWeek, effectiveRate = 0) {
      barChart = echarts.init(this.$refs.chart);
      const currentHour = moment();
      const baseConfigFoBar = {
        type: 'bar',
        barWidth: 15,
        barGap: 0,
        itemStyle:{
          borderRadius: [7.5, 7.5, 0, 0],
        },
      };

      const axisLine = {
        lineStyle: {
          color: colors.borderSecondary,
        }
      }

      const axisLabel = {
        color: colors.textPlaceholder,
        fontSize: 14,
      };

      const option = {
        title: {
          text: `{title|当日有效工时占比}{value|${(effectiveRate * 100).toFixed()}%}`,
          left: 28,
          textStyle: {
            rich: {
              title: {
                padding: [0, 40, 0, 0],
                fontWeight: 600,
                fontSize: 20,
                color: colors.deepGreen,
              },
              value: {
                fontWeight: 600,
                fontSize: 36,
                color: colors.black,
              }
            }
          }
        },
        legend: {
          data: ["当日平均总工时","上周平均总工时","当日有效工时占比"],
          orient: 'horizontal',
          right: 30,
          top: 15,
          itemWidth: 14,
          itemHeight: 14,
          icon: 'circle',
          textStyle: {
            fontSize: 12,
            color: colors.textPrimary,
          },
        },
        xAxis: {
          type: 'category',
          show: true,
          axisTick: {
            show: false,
          },
          data: dataForLastWeek.map(
            (item) => {
              return item['logTime'];
            }
          ),
          axisLabel: {
            formatter: '{value}',
            ...axisLabel,
          },
          axisLine,
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 1,
          interval: 0.2,
          axisLabel: {
            formatter: workingHoursFormatter,
            ...axisLabel,
          },
        },
        series: [{
          name: "当日平均总工时",
          ...baseConfigFoBar,
          data: dataForToday.map(
            (item) => {
              console.log(item);
              return (item.avgValidDuration / 60).toFixed(2);
            }
          ),
            color: [colors.deepGreen],
          label: {
            show: true,
                position: 'inside',
                formatter: (value) => `${(dataForToday[value.dataIndex]['avgValidDuration']).toFixed()}`,
                color: [colors.white],
              }
          },{
          name: "上周平均总工时",
          ...baseConfigFoBar,
          data: dataForLastWeek.map(
            (item) => {
              return (item.avgValidDuration / 60).toFixed(2);
            }
          ),
            color: ['#AFDCD9'],
          label: {
            show: true,
                position: 'inside',
                formatter: (value) => {
                    return `${(dataForLastWeek[value.dataIndex]['avgValidDuration']).toFixed()}`;
                  },
                color: [colors.white],
              }
          }, {
            type: 'line',
            name: "当日有效工时占比",
            data: dataForToday.map(
              (item) => {
                const isLater = currentHour.hour() < moment(item.logTime,"HH:ss").hour();
                    if(isLater){
                      return null;
                    }
                    return item.rate;
                  }
                ),
            color: colors.gray,
            label: {
                  show: true,
                  position: 'top',
                  color: colors.gray,
                  formatter: (value) => {
                    return `${(value.data * 100).toFixed()}%`
                  }
              },
        }],
        grid: {
          show: true,
          top: 90,
          left: 65,
          right: 30,
          bottom: 50,
        },
        tooltip: {
          trigger: 'item',
          formatter: (value) => `${(value.data * 100).toFixed()}%`
        },
      };

      barChart.setOption(option);
    }
  },
}
</script>

<style lang="scss" scoped>
  .chart-container {
    position: relative;
    padding-top: 30px;
    border-radius: 10px;
    @include background-color-content();
  }
  .loading {
    @include position-center();
    padding: 50px 0;
    font-size: 30px;
    color: $color-primary;
  }
  
</style>
