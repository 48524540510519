<template>
  <sf-card class="top-usage" title="软件使用TOP5" :loading="loading">
    <el-row class="title">
      <el-col :span="4">软件</el-col>
      <el-col :span="14" :offset="2">软件使用占比</el-col>
    </el-row>
    <el-row
      v-for="(item) in usage.slice(0, 5)"
      :key="item.name"
      class="software"
    >
      <el-col :span="4" class="name">
        {{item.name}}
      </el-col>
      <el-col :span="14" :offset="2" class="value">
        <div class="percentage-bar"><div class="filled" :style="{ width: `${item.value * 100}%`}"></div></div>
        {{(item.value * 100).toFixed()}}%
      </el-col>
    </el-row>
  </sf-card>
</template>

<script>
import { get } from 'lodash';
import * as api from '@constants/apis';

export default {
  data() {
    return {
      loading: false,
      usage: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_SOFTWARE_USAGE_TOP);

        this.usage = get(res, ['data'], []).map((item) => ({
          name: item.name,
          value: item.rate || 0,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.top-usage {
  margin: 0 -$padding;
  .title {
    @include text-regular($color-text-regular);
    > .el-col {
      text-align: center;
      &:first-child {
        text-align: right;
      }
    }
  }
  .software {
    padding-top: $padding;
    .name {
      @include text-primary($color-text-primary);
      text-align: right;
    }
    .value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include title-secondary($color-text-primary);
      .percentage-bar {
        width: 80%;
        height: 8px;
        border-radius: 8px;
        background-color: #EDEDED;
        overflow: hidden;
        .filled {
          height: 8px;
          border-radius: 8px;
          background-color: #5677C2;
        }
      }
    }
  }
}
</style>