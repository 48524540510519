<template>
  <div>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="16"
        :xl="16"
        class="home-card"
      >
        <EffectiveWorkingHours />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="8"
        :xl="8"
        class="home-card"
      >
        <Alarm />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="8"
        :xl="8"
        class="home-card"
      >
        <ActiveUser />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="8"
        :xl="8"
        class="home-card"
      >
        <SoftwareUsage />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="8"
        :xl="8"
        class="home-card"
      >
        <sf-card
          class="card"
          title="服务器使用率"
          :link="{ href: '/server-usage'}"
        >
          <ServerUsage/>
        </sf-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EffectiveWorkingHours from './components/effectiveWorkingHours';
import Alarm from './components/alarm';
import ActiveUser from './components/activeUser';
import SoftwareUsage from './components/softwareUsage';
import ServerUsage from './components/serverUsage';

export default {
  name: 'App',
  components: {
    EffectiveWorkingHours,
    Alarm,
    ActiveUser,
    SoftwareUsage,
    ServerUsage,
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.el-row {
  &:first-child {
    margin-top: -$padding;
  }
  .home-card {
    margin-top: $padding;
  }

}
.card {
  height: 100%;
}
.card-alarm {
  border-radius: 10px;
}
</style>
