<template>
  <sf-card
    class="card"
    title="在线用户数"
    :link="{ href: '/active-user'}"
    :loading="loading"
  >
    <div class="chart-container">
      <div class="chart-row">
        <div class="chart-area">
          <div ref="chart" class="chart"></div>
          <p class="layer">{{activeRate}}%</p>
        </div>
        <div class="lengend">
          <p
            v-for="item in users"
            :key="item.id"
          >
            <i :class="`iconfont icon-${item.id}-user`"></i>
            <span class="name">{{item.name}}</span>
            <span>{{item.value}}</span>
          </p>
        </div>
      </div>
      <TopUser />
    </div>
  </sf-card>
</template>

<script>
import { get } from 'lodash';
import * as echarts from 'echarts';
import * as api from '@constants/apis';
import TopUser from './topUser';
let chart = null;

export default {
  name: 'App',
  components: {
    TopUser,
  },
  data() {
    return {
      users: [
        {id: 'active', value: 0, name: '活跃用户', key: 'activateUsers'},
        {id: 'inactive', value: 0, name: '非活跃用户', key: 'nonActivateUsers'},
        {id: 'offline', value: 0, name: '离线用户', key: 'offlineUsers'},
      ],
      activeRate: 0,
      loading: false,
    }
  },
  mounted() {
    this.init().then(() => {
      window.addEventListener('resize', chart.resize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', chart.resize);
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_ONLINE_USER);

        this.users = this.users.map((item) => ({
          ...item,
          value: res.data[item.key] || 0,
        }));
        this.activeRate = (get(res, ['data', 'activateUserRate'], 0) * 100).toFixed(),
        this.renderChart();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    renderChart() {
      chart = echarts.init(this.$refs.chart);

      const option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '在线用户数',
            type: 'pie',
            radius: ['65%', '90%'],
            color: ['#4295C9', '#EEF4FE', '#D2D2D2'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            data: this.users,
          }
        ]
      };

      chart.setOption(option);
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-row {
  display: flex;
  justify-content: space-between;
  padding: $padding-l;
  @media screen and (max-width: 1440px) and (min-width: 1200px) {
    padding: $padding-l 0;
  }
}
.chart-area {
  position: relative;
  .chart {
    width: 200px;
    height: 200px;
  }
  .layer {
    @include position-center();
    font-size: 28px;
    font-weight: 600;
    color: $color-primary;
    line-height: 28px;
  }
}
.lengend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    display: flex;
    align-items: center;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    .iconfont {
      font-size: 28px;
    }
    .icon-active-user {
      color: #2289BB;
    }
    .icon-inactive-user {
      color: rgba(34, 137, 187, 0.5);
    }
    .icon-offline-user {
      color: #D2D2D2;
    }
    span {
      @include title-secondary($color-text-primary);
      font-weight: 600;
    }
    .name {
      margin-left: 14px; 
      margin-right: 45px; 
      flex: 1;
      @include text-primary($color-text-primary);
    }
    @media screen and (max-width: 1440px) and (min-width: 1200px) {
      span {
        font-size: 12px !important;
        &.name { margin: 0 $padding-s; }
      }
    }
  }
}
</style>

