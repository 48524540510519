<template>
  <sf-card
    class="card"
    title="软件使用分布"
    :link="{ href: '/software-usage'}"
    :loading="loading"
  >
    <div class="chart-container">
      <div class="chart-wrapper">
        <div ref="chart" class="chart"></div>
      </div>
      <TopUsage :usage="usage" />
    </div>
  </sf-card>
</template>

<script>
import * as echarts from 'echarts';
import * as api from '@constants/apis';
import colors from '@constants/colors';
import TopUsage from './topUsage';
let chart = null;

const labelFormatter = ({ name, value }) => `${name}\n${(value * 100).toFixed(1)}%`;
const typeColors = colors.softwareColors;

export default {
  name: 'App',
  components: {
    TopUsage,
  },
  data() {
    return {
      usage: [],
      loading: false,
    }
  },
  mounted() {
    this.init().then(() => {
      window.addEventListener('resize', chart.resize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', chart.resize);
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_SOFTWARE_USAGE);

        this.usage = res.data.map((item) => ({
          name: item.name,
          value: item.rate || 0,
        }));
        this.renderChart();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    renderChart() {
      chart = echarts.init(this.$refs.chart);

      const option = {
        tooltip: {
          trigger: 'item'
        },
        formatter: labelFormatter,
        series: [
          {
            name: '软件使用分布',
            type: 'pie',
            radius: ['65%', '80%'],
            color: typeColors,
            avoidLabelOverlap: false,
            label: {
              formatter: labelFormatter,
              textStyle: {
                color: colors.textSecondary,
                fontSize: 14,
              }
            },
            labelLine: {
              lineStyle: {
                color: colors.textSecondary,
              }
            },
            data: this.usage,
          }
        ]
      };

      chart.setOption(option);
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-row {
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
}
.chart-wrapper {
  .chart {
    height: 260px;
  }
}
.chart-area {
  position: relative;
  .chart {
    // width: 200px;
    height: 200px;
  }
  .layer {
    @include position-center();
    font-size: 28px;
    font-weight: 600;
    color: $color-primary;
    line-height: 28px;
  }
}
.lengend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    display: flex;
    align-items: center;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    .iconfont {
      font-size: 28px;
    }
    .icon-active-user {
      color: #2289BB;
    }
    .icon-inactive-user {
      color: rgba(34, 137, 187, 0.5);
    }
    .icon-offline-user {
      color: #D2D2D2;
    }
    span {
      @include title-secondary($color-text-primary);
      font-weight: 600;
    }
    .name {
      margin-left: 14px; 
      margin-right: 45px; 
      flex: 1;
      @include text-primary($color-text-primary);
    }
  }
}
</style>

