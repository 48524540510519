<template>
  <sf-card
    class="top-user"
    title="TOP USER"
    :link="{ href: '/active-user/details'}"
    :loading="loading"
  >
    <el-row class="title">
      <el-col :span="6">用户名</el-col>
      <el-col :span="6">当天工作<br/>有效时长占比</el-col>
      <el-col :span="6">当天工作<br/>有效时长</el-col>
      <el-col :span="6">过去7天<br/>平均有效工作时长</el-col>
    </el-row>
    <el-row
      v-for="item in topUsers"
      :key="item"
      class="user"
    >
      <el-col :span="6" class="name">
        <i class="iconfont icon-badge"></i>
        <span>{{item.name}}</span>
      </el-col>
      <el-col :span="6" class="effective-work">
        {{item.effectiveRate}}%
      </el-col>
      <el-col :span="6" class="effective-work">
        {{item.effectiveHours}}
        <span :class="`unit ${item.isEffectiveHoursIncreased ? 'up' : 'down'}`">hrs</span>
      </el-col>
      <el-col :span="6" class="effective-average">
        {{item.averageEffectiveHours}}
        <span class="unit">hrs</span>
      </el-col>
    </el-row>
  </sf-card>
</template>

<script>
import BigNumber from 'bignumber.js';
import * as api from '@constants/apis';

export default {
  data() {
    return {
      topUsers: [],
      loading: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_TOP_USER);
        this.topUsers = res.data.map((item) => ({
          name: item.username,
          effectiveRate: Number(BigNumber(item.validWorkRate).times(100)),
          effectiveHours: item.todayDuration,
          averageEffectiveHours: item.lastSevenDaysDuration,
          isEffectiveHoursIncreased: item.todayDuration >= item.lastSevenDaysDuration,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-user {
  margin: 0 -$padding;
  .title {
    @include text-regular($color-text-regular);
    > .el-col {
      @include flex-center(column);
      &:first-child {
        align-items: flex-start;
        text-indent: 2.5em;
      }
    }
  }
  .user {
    padding-top: $padding;
    .unit {
      @include text-regular($color-text-secondary);
      &.up:after, &.down:after {
        position: relative;
        content: '';
        display: inline-block;
        margin-left: 5px;
      }
      &.up {
        &:after {
          top: -3px;
          @include triangle-up(6px, $color-success);
        }
      }
      &.down {
        &:after {
          top: 6px;
          @include triangle-down(6px, $color-danger);
        }
      }
    }
    .name {
      @include flex-center();
      @include text-primary($color-text-primary);
      .iconfont {
        font-size: 20px;
        color: #B7D2FF;
      }
      span {
        flex: 1;
        margin-left: $padding-m;
      }
    }
    .effective-work {
      text-align: center;
      @include text-primary(#3BB7AE);
      font-weight: 500;
    }
    .effective-average {
      text-align: center;
      @include text-primary($color-text-secondary);
      font-weight: 500;
    }
    &:nth-child(2) {
      .name {
        .iconfont {
          color: $color-primary;
        }
      }
    }
    &:last-child {
      padding-bottom: $padding-s;
    }
  }
}
</style>