<template>
  <sf-card
    class="card card-alarm"
    title="预警"
    :link="{ href: '/alarm'}"
  >
    <el-row class="alarm-number-container">
      <el-col
        v-for="item in alarmNumbers"
        :key="item.id"
        :span="24 / alarmNumbers.length"
        class="alarm-number"
      >
        <p class="title">{{item.title}}</p>
        <p :class="`value ${item.className}`">{{item.value}}</p>
      </el-col>
    </el-row>
  </sf-card>
</template>

<script>
import * as api from '@constants/apis';

export default {
  data() {
    return {
      alarmNumbers: [
        { id: 'all', className: 'all', title: '总预警数', value: 0, key: 'total' },
        { id: 'complete', className: 'complete', title: '已处理', value: 0, key: 'processed' },
        { id: 'pending', className: 'pending', title: '待处理', value: 0, key: 'processing' },
      ],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$httpRequest.get(api.HOME_ALARM);
      this.alarmNumbers = this.alarmNumbers.map((item) => ({
        ...item,
        value: res.data[item.key],
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.alarm-number-container {
  height: 100%;
}
.alarm-number {
  @include flex-center(column);
  .title {
    @include title-secondary($color-text-primary);
  }
  .value {
    margin-top: 25px;
    font-size: 44px;
    font-weight: 500;
    &.all {
      color: $color-danger;
    }
    &.complete {
      color: $color-success;
    }
    &.pending {
      color: $color-warning;
    }
  }
}
</style>

