<template>
<div class="chart-container">
  <div ref="chart" style="height: 300px"></div>
  <p class="intro" style="margin-top: 82px">说明：</p>
  <p class="intro" style="margin-top: 10px">基于思流大数据分析和经验，为提升您的用户体验，我们提供了相关硬件指标的最优建议，您可点击右侧的链接了解更多。</p>
</div>
</template>

<script>
import * as echarts from 'echarts';
import * as api from '@constants/apis';

let barChart = null;

export default {
  name: 'App',
  mounted() {
    this.init().then(() => {
      window.addEventListener('resize', barChart.resize);
    });
  },
  beforeUnmount() {
    if (barChart && barChart.resize) {
      window.removeEventListener('resize', barChart.resize);
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$httpRequest.get(api.HOME_SERVER_USAGE);
        const serverUsage = res.data.reverse().map((item) => {
          return [`${item.id},(建议值：${(item.recommendation * 100).toFixed(0)}%)`,(item.usageRate*100)];
        });
        this.renderChart(serverUsage);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    renderChart(usageData) {
      barChart = echarts.init(this.$refs.chart);
      const baseConfigFoBar = {
        type: 'bar',
        barWidth: '60%',
        labelLayout: this.labelLayout,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 1, 1,
            [{
                offset: 0,
                color: '#3591C1'
              },
              {
                offset: 1,
                color: '#A1B3BB'
              }
            ]
          ),
          emphasis: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 1, 1,
              [{
                  offset: 0,
                  color: '#3A92C1'
                },
                {
                  offset: 1,
                  color: '#D78B19'
                }
              ]
            ),

          },
        }
      };

      const option = {
        grid: {
          left: '106px',
          bottom: 0,
          height: '100%',
        },
        dataset: {
          source: [
            ...usageData
          ],
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e9e9e9',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          }
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e9e9e9',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            padding: [0, 0, 0, 0],
            color: '#373E4E',
            formatter: function (value) {
              var strs = value.split(',')
              return '{mainLabel|' + strs[0] + '}\n{subLabel|' + strs[1] + '}';
            },
            rich: {
              mainLabel: {
                align: 'left',
                color: '#373E4E',
                fontSize: '16px',
                padding: [0, 0, 10, 0],
              },
              subLabel: {
                align: 'left',
                color: '#999999',
                fontSize: '12px',
              },
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          }
        },
        series: [{
          ...baseConfigFoBar,
        }],
      };

      barChart.setOption(option);
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-container {
  width: '100%';
  background: $color-white;
  border-radius: 10px;
}

.intro {
  @include text-regular($color-text-regular);
  
}
</style>
