export const workingHoursFormatter = (value) => {
  const texts = [];
  if (value <= 0) {
    texts.push('');
  } else if (value <= 0.2) {
    texts.push('20%');
  } else if (value<= 0.4) {
    texts.push('40%');
  } else if(value<= 0.6){
    texts.push('60%');
  } else if (value<= 0.8) {
    texts.push('80%');
  } else {
    texts.push('100%');
  }
  return texts;
};